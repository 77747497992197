import React from 'react';
import { Link } from 'react-router-dom';
import { LINK_URL,REACT_APP_TENANT } from './../../config/constant';
import triivaco_logo from './../login/assets/images/Triivco_header_logo.svg';

class Brandlogo extends React.Component {
    
    render() {
    return (
        <>
            {
            REACT_APP_TENANT == "tri-id" || REACT_APP_TENANT == "fav-id" ? 
            <Link className="navbar-brand" to="/"><img src={triivaco_logo} alt='' /></Link>
            :<Link className="navbar-brand" to="/"><img src={require('./../login/assets/images/'+LINK_URL.LOGO)} alt=''/></Link>
            }
        </>
               
        );
    }
}
export default Brandlogo;