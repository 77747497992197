import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { LINK_URL, CONF_VAL, REACT_APP_TENANT } from './../../../config/constant';
import triivaco_banner from './../assets/images/triivaco_banner.svg';
import triivaco_logo from './../assets/images/triivaco_logo.svg';

class LeftPanel extends Component {

    render() {
        return (

            <div className="leftBlueBg login-left-panel">
                {REACT_APP_TENANT == "tri-id" || REACT_APP_TENANT == "fav-id" ?
               <div class="login-left-panel"><div class="login-bg">
                   <div class="logo-img">
                       <img src={triivaco_logo}  class=""></img>
                       </div><div class="login-txt"><h1>Bringing Your  Dreams to Reality!</h1></div>
                       <img src={triivaco_banner} class="login-bg-img">
                           </img></div></div>
                    :

                    <div className="blueBg">
                        <div className="logoSec"><img src={require('./../assets/images/' + LINK_URL.LOGO)} alt='' /></div>
                        <h1 className="lgHeading"><strong>{this.props.t('login.grow')}</strong> <span className="txt87">{this.props.t('login.your_business')}</span></h1>
                        <p>{this.props.t('login.business_text')}</p>
                        <div className="playStore">
                            <a href="http://play.google.com/store/apps/details?id=com.oto.dealer.id" target="_blank" rel="noreferrer"><img src={require('./../assets/images/google-play.png')} alt="" /></a>
                            <a href="https://itunes.apple.com/us/app/oto-dealer/id1489698884?ls=1&amp;mt=8" target="_blank" rel="noreferrer"><img src={require('./../assets/images/app-store.png')} alt="" /></a>
                        </div>
                        <div className="homeMobile">
                            <img className="img-mobile-bg" src={require('./../assets/images/' + CONF_VAL.MOBILE_LOGIN_IMAGE)} alt="" />
                            <img className="img-mobile-bg1" src={require('./../assets/images/' + CONF_VAL.MOBILE_LOGIN_IMAGE)} alt="" />
                            {/* <video width="229" className="true" className="videoSec">
                            <source src={require('./../assets/images/videos.mp4')} type="video/mp4" />
                            {this.props.t('login.not_support')}
                        </video> */}

                        </div>
                        <div className="animationImage">
                            <img src={require('./../assets/images/car_animation_new.png')} alt="" />
                        </div>
                    </div>}
            </div>
        );
    }
}

export default withTranslation('common')(LeftPanel);